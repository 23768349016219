<template>
    <AppIcon v-bind="$props">
        <!-- eslint-disable max-len -->
        <path
            d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill="#00A807"
            fill-opacity="0.2"
        />

        <path
            d="M10 17.6134L5.86 13.4734L7.74667 11.5868L10 13.8468L16.5867 7.25342L18.4733 9.14008L10 17.6134Z"
            fill="#00A807"
        />
        <!-- eslint-enable max-len -->
    </AppIcon>
</template>
